var render = function render(){
  var _vm$termsItem;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.lazyTemplate, {
    tag: "component"
  }, [_c('v-layout', {
    attrs: {
      "justify-center": "",
      "align-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "py-8",
    attrs: {
      "width": "100vw",
      "max-width": "1200"
    }
  }, [_c('v-card', {
    attrs: {
      "width": "100%"
    }
  }, [_c('v-card-title', {
    staticClass: "headline"
  }, [_c('span', {
    staticClass: "py-4 pl-4"
  }, [_c('b', [_vm._v("이용약관")])])]), _c('v-divider'), _c('v-card-text', [_c('v-responsive', {
    staticClass: "overflow-auto",
    attrs: {
      "height": "50vh"
    }
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s((_vm$termsItem = _vm.termsItem) === null || _vm$termsItem === void 0 ? void 0 : _vm$termsItem.content)
    }
  })])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }