<template>
    <component :is="lazyTemplate">
        <v-layout justify-center align-center>
            <v-responsive width="100vw" max-width="1200" class="py-8">
                <v-card width="100%">
                    <v-card-title class="headline">
                        <span class="py-4 pl-4"><b>이용약관</b></span>
                    </v-card-title>
                    <v-divider/>
                    <v-card-text>
                        <v-responsive height="50vh" class="overflow-auto">
                            <p v-html="termsItem?.content" />
                        </v-responsive>
                    </v-card-text>
                </v-card>
            </v-responsive>
        </v-layout>
    </component>
</template>

<script>
import api from "@/api";
export default {
    data(){
        return {
            termsItem: null
        };
    },
    async mounted(){
        await this.init();
    },
    methods: {
        async init(){
            let { termsList } = await api.v1.terms.gets({ params: { code: "tos" } });
            this.termsItem = termsList[0];
        }
    },
    computed: {
        lazyTemplate(){
            return () => import(`@/templates/${this.$theme.pages.login}`);
        }
    }
}
</script>